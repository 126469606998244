<template>
  <div v-if="model">
    <div v-if="isYoutubeLink" class="youtubeOverlay"></div>
    <iframe v-if="isYoutubeLink" type="text/html" :src="getVideoSrc" frameborder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

    <div class="" v-if="!isYoutubeLink && model.youtubeLink">
      <iframe :src="model.youtubeLink" width="100%" height="100%" style="position: fixed; z-index:9999"
        allowfullscreen="true" webkitallowfullscreen="true" mozallowfullscreen="true" />
    </div>
  </div>
</template>

<script>
export default {
  name: "youtube",
  props: ["data"],
  data() {
    return {
    };
  },
  computed: {
    isYoutubeLink() {
      let link = this.model.youtubeLink;
      if (link && link.length) {
        // check if link belongs to youtube or not if not show web page in object element
        if (link.toLowerCase().indexOf('youtube') !== -1 ||
          link.toLowerCase().indexOf('youtu') !== -1) {
          return true;
        }
      }
      return false;
    },
    model() {
      return this.$store.state;
    },
    getVideoSrc() {
      let youtubeVideoId = "",
        youtubePlaylistId = "";
      let isMute = 1;
      let link = this.model.youtubeLink;
      //window.console.log(link, link.toLowerCase().indexOf('youtube'))
      if (link && link.length) {
        // check if link belongs to youtube or not if not show web page in object element
        //https://youtu.be/Z_zexAsRl_g?si=zno98NdroDgzKgoU
        if (link.toLowerCase().indexOf('youtube') === -1 &&
          link.toLowerCase().indexOf('youtu') === -1) {//https://youtu.be/Ictef4z9Nl0
          return;
        }
        if (link.indexOf("hasVoice") !== -1) {
          isMute = 0;
          link = link.replace("hasVoice", "");
        }
        if (link.indexOf("youtu.be") !== -1) {
          var queryStr = link.split("?");
          if (queryStr && queryStr.length) {
            link = queryStr[0];
          }
          var splitedLink = link.split("/");

          youtubeVideoId = splitedLink[splitedLink.length - 1];
        } else {
          var queryString = link.split("?")[1];
          const urlParams = new URLSearchParams(queryString);
          youtubeVideoId = urlParams.get("v");
          youtubePlaylistId = urlParams.get("list");
        }
      }

      if (youtubePlaylistId && youtubePlaylistId.length)
        return `https://www.youtube.com/embed/videoseries?loop=1&mute=${isMute}&list=${youtubePlaylistId}&controls=0&showinfo=0&autoplay=1`;
      return `https://www.youtube.com/embed/${youtubeVideoId}?rel=0&modestbranding=1&autohide=1&mute=${isMute}&showinfo=0&controls=0&loop=1&autoplay=1&playlist=${youtubeVideoId}`;
    },
  },
};
</script>

<style lang="scss" scoped>
iframe {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
}

.youtubeOverlay {
  position: fixed;
  min-width: 100%;
  height: 33%;
  z-index: 100;
  background: transparent;
}
</style>
